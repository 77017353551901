import React from "react";
import { matchPath } from "react-router-dom";

import SidebarNavList from "./SidebarNavList";
import NavListItem from "./items/NavListItem";
import NavListItemHead from "./items/NavListItemHead";
import NavListItemLink from "./items/NavListItemLink";
import NavListItemAction from "./items/NavListItemAction";
import NavListItemHeadUser from "./items/NavListItemHeadUser";
import NavListItemLanguage from "./items/NavListItemLanguage";

const reduceChildRoutes = (props) => {
  const { items, page, depth, currentRoute } = props;
  const { kind = "default" } = page;

  if (page.children) {
    const open =
      page.open ||
      (page.href
        ? !!matchPath(
            {
              path: page.href,
              end: false,
            },
            currentRoute
          )
        : false);
    items.push(
      <NavListItem
        open={!!open}
        depth={depth}
        key={`navlist-${page.title}`}
        {...page}
      >
        <SidebarNavList depth={depth + 1} pages={page.children} />
      </NavListItem>
    );
    return items;
  }

  if (kind === "head") {
    items.push(
      <NavListItemHead
        depth={depth}
        key={`navlist-${kind}-${page.title}`}
        {...page}
      />
    );
    return items;
  }

  if (page.kind === "link") {
    items.push(
      <NavListItemLink
        depth={depth}
        key={`navlist-${kind}-${page.title}`}
        {...page}
      />
    );
    return items;
  }

  if (page.kind === "action") {
    items.push(
      <NavListItemAction
        depth={depth}
        key={`navlist-${kind}-${page.title}`}
        {...page}
      />
    );
    return items;
  }

  if (page.kind === "language") {
    items.push(
      <NavListItemLanguage
        depth={depth}
        key={`navlist-${kind}-${page.title}`}
        {...page}
      />
    );
    return items;
  }

  if (kind === "user") {
    items.push(
      <NavListItemHeadUser
        depth={depth}
        key={`navlist-${kind}-${page.title}`}
        {...page}
      />
    );
    return items;
  }

  items.push(
    <NavListItem depth={depth} key={`navlist-${page.title}`} {...page} />
  );
  return items;
};

export default reduceChildRoutes;
