import { Formik } from "formik";
import {
  Button,
  Card,
  Col,
  FloatingLabel,
  Form,
  OverlayTrigger,
  Row,
  Tooltip,
} from "react-bootstrap";
import useBots from "../../hooks/useBots";
import * as Yup from "yup";
import { BOT_STATUS, BOT_KIND } from "../../contexts/bots/BotsContext";
import useMessage from "../../hooks/useMessage";
import { useTranslation } from "../../hooks/useLocalization";
import useClipboard from "../../hooks/useClipboard";
import { isExperimental } from "../../system/system";
import { Languages } from "../../languages/languages";

import useAnalytics from "../../hooks/useAnalytics";

const GeneralSettings = (props) => {
  const { t, tt } = useTranslation();
  // const { user } = useAuth();
  const { onCopyEvent } = useClipboard("Bot bundle");
  const { showMessage, showMessageWithDebug } = useMessage();
  const { activeBot, updateBot, generateBundle } = useBots();
  const { CONSOLE_EVENTS } = useAnalytics();

  // const categoryOptions = useMemo(
  //   () =>
  //     BOT_CATEGORY_LIST.map((category) => ({
  //       value: category,
  //       label: category,
  //     })),
  //   []
  // );

  // const sharedOptions = useMemo(
  //   () => [
  //     {
  //       value: user?.uid,
  //       label: user?.displayName || "Unknown",
  //     },
  //   ],
  //   [user]
  // );

  // const linkedOptions = useMemo(
  //   () => [
  //     {
  //       value: activeBot?.bundle,
  //       label: activeBot?.bundle || "Unknown",
  //     },
  //   ],
  //   [activeBot]
  // );

  return (
    <Card>
      <Card.Header>
        <Card.Title tag="h5" className="mb-0">
          {t("General info")}
        </Card.Title>
      </Card.Header>
      <Card.Body>
        <Formik
          enableReinitialize
          initialValues={{
            name: activeBot?.name || "",
            description: activeBot?.description || "",
            lang: activeBot?.lang || "en",
            status: activeBot?.status || BOT_STATUS.DEV,
            kind: activeBot?.kind || BOT_KIND.USER,
            category: activeBot?.category || [],
            //bundle: activeBot?.bundle || "",
            // Чтобы заработало, нужно повесить обработчик ввода на name, который будет заменять бандл, а при отправке отправлять бандл из поля bundle
          }}
          validationSchema={Yup.object().shape({
            name: Yup.string()
              .min(5, tt("Must be at least N characters", 5))
              .max(150)
              .matches(
                /^[aA-zZ\s0-9_()]+$/g,
                t("Must contain only Latin characters")
              ),
            // .required(t("Bot name is required")),
            description: Yup.string().max(255),
          })}
          onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
            const access =
              values.status === BOT_STATUS.PUBLISH ? "public" : "private"; // deprecated

            ({ values } = {
              values: {
                ...values,
                bundle: generateBundle(values.name),
                access,
              },
            });

            try {
              if (await updateBot(activeBot?.id, { ...values })) {
                showMessage({ save: "" });
                await CONSOLE_EVENTS.EV_GeneralSettings.send({ action: 'update', params: { ...values } })
              } else {
                showMessage({ save: t("The data is up to date") });
              }
            } catch (error) {
              const { message = t("Something went wrong"), debug } = error;
              showMessageWithDebug({ error: message, debug });
              setErrors({ submit: message });
              setStatus({ success: false });
              setSubmitting(false);
            }
          }}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            isSubmitting,
            touched,
            values,
          }) => (
            <Form onSubmit={handleSubmit}>
              {/* {errors.submit && (
                  <Alert className="align-items-center" variant="danger">
                    <div className="alert-message">{errors.submit}</div>
                  </Alert>
                )} */}
              <Row>
                <Col xl md="6">
                  <Form.Group className="mb-3">
                    <FloatingLabel label={`${t("Bot name")} *`}>
                      <Form.Control
                        required
                        id="name"
                        type="text"
                        name="name"
                        placeholder={`${t("Bot name")} *`}
                        value={values.name}
                        isInvalid={Boolean(touched.name && errors.name)}
                        onBlur={handleBlur}
                        onChange={handleChange}
                      />
                      {!!touched.name && (
                        <Form.Control.Feedback type="invalid">
                          {errors.name}
                        </Form.Control.Feedback>
                      )}
                    </FloatingLabel>
                  </Form.Group>
                </Col>
                <Col>
                  <OverlayTrigger
                    placement="bottom"
                    overlay={
                      <Tooltip>
                        {t("Bundle is generated automatically")}
                      </Tooltip>
                    }
                  >
                    <Form.Group className="mb-3">
                      <FloatingLabel label={t("Bot bundle")}>
                        <Form.Control
                          readOnly
                          id="bundle"
                          type="text"
                          name="bundle"
                          placeholder={t("Bot bundle")}
                          className="cursor-copy"
                          value={generateBundle(values.name) || ""} //{values.bundle || ""}
                          onClick={onCopyEvent}
                        />
                      </FloatingLabel>
                    </Form.Group>
                  </OverlayTrigger>
                </Col>
              </Row>

              <Form.Group className="mb-3">
                <FloatingLabel label={t("Description")}>
                  <Form.Control
                    as="textarea"
                    id="description"
                    type="text"
                    name="description"
                    placeholder={t("Description")}
                    value={values.description}
                    isInvalid={Boolean(
                      touched.description && errors.description
                    )}
                    onBlur={handleBlur}
                    onChange={handleChange}
                  />
                  {!!touched.description && (
                    <Form.Control.Feedback type="invalid">
                      {errors.description}
                    </Form.Control.Feedback>
                  )}
                </FloatingLabel>
              </Form.Group>

              <Form.Group className="mb-3">
                <Form.Label htmlFor="lang">{t("Default language")}</Form.Label>
                <Form.Select
                  id="lang"
                  name="lang"
                  value={values.lang}
                  onBlur={handleBlur}
                  onChange={handleChange}
                >
                  {Object.keys(Languages).map((value, index) => (
                    <option key={index} value={value}>
                      {Languages[value].name}
                    </option>
                  ))}
                </Form.Select>
              </Form.Group>

              <Form.Group className="mb-3">
                <Form.Label htmlFor="status">{t("Bot status")}</Form.Label>
                <Form.Select
                  id="status"
                  name="status"
                  value={values.status}
                  onBlur={handleBlur}
                  onChange={handleChange}
                >
                  <option value={BOT_STATUS.DEV}>
                    {t("In development")}
                  </option>
                  <option value={BOT_STATUS.REVIEW}>
                    {t("Under review")}
                  </option>
                  <option value={BOT_STATUS.PUBLISH}>{t("Published")}</option>
                  <option value={BOT_STATUS.MAINTENANCE}>
                    {t("Maintenance")}
                  </option>
                </Form.Select>
              </Form.Group>

              {isExperimental() && (
                <Form.Group className="mb-3">
                  <Form.Label htmlFor="kind">{t("Bot kind")}</Form.Label>
                  <Form.Select
                    id="kind"
                    name="kind"
                    value={values.kind}
                    onBlur={handleBlur}
                    onChange={handleChange}
                  >
                    <option value={BOT_KIND.HELP}>{t("Assistant")}</option>
                    <option value={BOT_KIND.USER}>{t("User")}</option>
                    <option value={BOT_KIND.DEFAULT}>{t("Init")}</option>
                    <option value={BOT_KIND.TEMPLATE}>{t("Template")}</option>
                    <option value={BOT_KIND.EXAMPLE}>{t("Example")}</option>
                  </Form.Select>
                </Form.Group>
              )}

              {/* <Form.Group className="mb-3">
                <Form.Label htmlFor="category">Bot category</Form.Label>
                <Select
                  id="category"
                  className="react-select-container"
                  classNamePrefix="react-select"
                  defaultValue={values.category.map((category) => ({
                    value: category,
                    label: category,
                  }))}
                  options={categoryOptions}
                  isMulti
                />
              </Form.Group> */}

              {/* <Form.Group className="mb-3">
                <Form.Label htmlFor="shared">Shared with</Form.Label>
                <Select
                  id="shared"
                  className="react-select-container"
                  classNamePrefix="react-select"
                  defaultValue={sharedOptions}
                  options={sharedOptions}
                  isMulti
                />
              </Form.Group> */}

              {/* <Form.Group className="mb-3">
                <Form.Label htmlFor="linked">Linked with</Form.Label>
                <Select
                  id="linked"
                  className="react-select-container"
                  classNamePrefix="react-select"
                  defaultValue={linkedOptions}
                  options={linkedOptions}
                  isMulti
                />
              </Form.Group> */}

              <div className="text-center mt-3">
                <Button type="submit" variant="primary" disabled={isSubmitting}>
                  {t("Save changes")}
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      </Card.Body>
    </Card>
  );
};

export default GeneralSettings;
