import React, { useEffect, useReducer } from "react";
import api from "../../net/api";
import useAuth from "../../hooks/useAuth";
import * as CMD from "./TemplatesReducer";
import logger from "../../utils/logger";
import { useTranslation } from "../../hooks/useLocalization";

const TemplatesContext = React.createContext(CMD.templatesState);

function TemplatesProvider({ children }) {
  const { t } = useTranslation();
  const { isInitialized, user, secureAPIRequest } = useAuth();
  const [state, dispatch] = useReducer(CMD.TemplatesReducer, CMD.templatesState);

  // Restore Session
  // ------------------------
  useEffect(() => {
    (async () => {

      if (!(isInitialized && user)) {
        // waiting for auth
        return;
      }

      try {
        const { groups } = await fetchTemplates();
        dispatch({
          type: CMD.INITIALIZE,
          payload: {
            groups
          },
        });

      } catch (e) {
        logger.error(e);
        dispatch({
          type: CMD.INITIALIZE,
          payload: {
            groups: [],
          },
        });
      }
    })();

  }, [isInitialized, user]);

  const fetchTemplates = async () => {
    const { uid } = user;
    const response = await secureAPIRequest(api.MANAGE.TEMPLATES_FETCH, { user: { uid: uid } });
    const { data } = response;
    return { ...data };
  };

  return (
    <TemplatesContext.Provider
      value={{
        ...state,
      }}
    >
      {children}
    </TemplatesContext.Provider>
  );
}

export {
  TemplatesProvider,
  TemplatesContext,
};
