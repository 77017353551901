import React, { useCallback, useEffect, useReducer, useState } from "react";
import api from "../../net/api";
import useAuth from "../../hooks/useAuth";
import useSettingsState from "../../hooks/useSettingsState";
import useLocationContext from "../../hooks/useLocationContext";
import {
  arrayBufferToBase64Async,
  downloadBase64File,
} from "../../utils/download";
import BotFileSystem from "../../system/BotFileSystem";
import {
  botById,
  compareLocalFiles,
  findFileOpt,
  getBotFileSetup,
  parseLocalFiles,
} from "./utils";
import useMessage from "../../hooks/useMessage";
import * as CMD from "./reducer";
import Thread from "../../utils/thread";
import logger from "../../utils/logger";
import { matchPath, useLocation } from "react-router-dom";
import { isExperimental } from "../../system/system";
import { REGEXP_YML } from "../../utils/files";
import { useTranslation } from "../../hooks/useLocalization";

const BOT_SEARCH = {
  BID_KEY: "bid",
  CID_KEY: "cid",
};

const BOT_KIND = {
  HELP: "help",
  USER: "user",
  DEFAULT: "default",
  TEMPLATE: "template",
  EXAMPLE: "example",
};

const BOT_STATUS = {
  DEV: "dev",
  REVIEW: "review",
  PUBLISH: "publish",
  MAINTENANCE: "maintenance",
};

const BOT_FILE_OPTION = {
  MAIN: "main",
  ICON: "icon",
  STYLE: "style",
  SETUP: "setup",
};

const BOT_FILE_STATUS = {
  IDLE: "",
  CREATE: "non-versioned",
  UPDATE: "modified",
  REMOVE: "missing",
};

const BOT_FILE_GROUP = {
  FILE: "file",
};

const BOT = {
  title: (name) => {
    if (BOT_CHANNEL.title(name))
      return BOT_CHANNEL.title(name)
    else if (BOT_MODULE.title(name))
      return BOT_MODULE.title(name)
    return false
  }

}

const BOT_CHANNEL = {
  WEB: "web",
  VIBER: "viber",
  TELEGRAM: "telegram",
  FACEBOOK: "facebook",
  WHATSAPP: "whatsapp",
  LIVE_CHAT: "live_chat",
  WORDPRESS: "wordpress",
  ZENDESK: "zendesk",
  DROPBOX: "dropbox",
  GOOGLE_DRIVE: "google_drive",
  GOOGLE_CALENDAR: "google_calendar",
  GOOGLE_SHEETS: "google_sheets",
  title: (name) =>
    (name === BOT_CHANNEL.WEB && "WebChat") ||
    (name === BOT_CHANNEL.VIBER && "Viber") ||
    (name === BOT_CHANNEL.TELEGRAM && "Telegram") ||
    (name === BOT_CHANNEL.FACEBOOK && "Messenger") ||
    (name === BOT_CHANNEL.WHATSAPP && "WhatsApp") ||
    (name === BOT_CHANNEL.LIVE_CHAT && "LiveChat") ||
    (name === BOT_CHANNEL.GOOGLE_DRIVE && "Google Drive") ||
    (name === BOT_CHANNEL.GOOGLE_CALENDAR && "Google Calendar") ||
    (name === BOT_CHANNEL.GOOGLE_SHEETS && "Google Sheets") ||
    (name === BOT_CHANNEL.ZENDESK && "Zendesk") ||
    (name === BOT_CHANNEL.DROPBOX && "Dropbox") ||
    (name === BOT_CHANNEL.WORDPRESS && "Wordpress"),
};

const BOT_MODULE = {
  CHATGPT: "chat_gpt",
  title: (name) =>
    (name === BOT_MODULE.CHATGPT && "ChatGPT"),
}

const BOT_GPT_MODEL = {
  GPT_35: "gpt-3.5-turbo",
  GPT_35_0301: "gpt-3.5-turbo-0301",
  GPT_35_0613: "gpt-3.5-turbo-0613",
  GPT_35_16k: "gpt-3.5-turbo-16k",
  GPT_35_16k_0613: "gpt-3.5-turbo-16k-0613",
  GPT4: "gpt-4",
  GPT4_0613: "gpt-4-0613",
  GPT4_32k: "gpt-4-32k",
  GPT4_32k_0613: "gpt-4-32k-0613",
  getKeys: () => [
    BOT_GPT_MODEL.GPT_35,
    BOT_GPT_MODEL.GPT_35_0301,
    BOT_GPT_MODEL.GPT_35_0613,
    BOT_GPT_MODEL.GPT_35_16k,
    BOT_GPT_MODEL.GPT_35_16k_0613,
    BOT_GPT_MODEL.GPT4,
    BOT_GPT_MODEL.GPT4_0613,
    BOT_GPT_MODEL.GPT4_32k,
    BOT_GPT_MODEL.GPT4_32k_0613
  ],
  getMaxTokens: (name) =>
    (name === BOT_GPT_MODEL.GPT_35 && 4096) ||
    (name === BOT_GPT_MODEL.GPT_35_0301 && 4096) ||
    (name === BOT_GPT_MODEL.GPT_35_0613 && 4096) ||
    (name === BOT_GPT_MODEL.GPT_35_16k && 16384) ||
    (name === BOT_GPT_MODEL.GPT_35_16k_0613 && 16384) ||
    (name === BOT_GPT_MODEL.GPT4 && 8192) ||
    (name === BOT_GPT_MODEL.GPT4_0613 && 8192) ||
    (name === BOT_GPT_MODEL.GPT4_32k && 32768) ||
    (name === BOT_GPT_MODEL.GPT4_32k_0613 && 32768),
};

const BOT_CATEGORY = {
  DISABLED: "",
  FEATURED: "Featured",
  BANKING: "Banking",
  CUSTOMER_SERVICE: "Customer Service",
  ONLINE_STORE: "Online Store",
  HOSPITALITY: "Hospitality",
  BUSINESS: "Business",
  HEALTHCARE: "Healthcare",
  EDUCATION: "Education",
  MARKETING_TOOLS: "Marketing/Tools",
};

const BOT_CATEGORY_LIST = [
  BOT_CATEGORY.FEATURED,
  BOT_CATEGORY.BANKING,
  BOT_CATEGORY.CUSTOMER_SERVICE,
  BOT_CATEGORY.ONLINE_STORE,
  BOT_CATEGORY.HOSPITALITY,
  BOT_CATEGORY.BUSINESS,
  BOT_CATEGORY.HEALTHCARE,
  BOT_CATEGORY.EDUCATION,
  BOT_CATEGORY.MARKETING_TOOLS,
];

const BotsContext = React.createContext(CMD.initialState);
const MainThread = new Thread(2000);

function BotsProvider({ children }) {
  const { t } = useTranslation();
  const location = useLocation();
  const { setSearchParam, deleteSearchParam } = useLocationContext();
  const { pathname, search } = location;
  const { showMessageWithDebug, updateMessageDebug, dismissAllMessages } =
    useMessage();
  const { isInitialized, isAuthenticated, user, secureAPIRequest, checkPermission } = useAuth();
  const [state, dispatch] = useReducer(CMD.BotsReducer, CMD.initialState);
  const [settingsRestored, setSettingsRestored] = useState();
  const [sessionRestored, setSessionRestored] = useState();
  const [storedSettings, setStoredSettings] = useSettingsState(
    "dashboardBots",
    {
      bots: [],
      selected: {},
      recentIDs: [],
    }
  );

  // Restore settings
  // ------------------------
  useEffect(() => {
    (async () => {
      const storedBots = await Promise.all(
        (storedSettings.bots || []).map(async (storedBot) => {
          const { id } = storedBot;
          storedBot.fs = await BotFileSystem.restoreDirectory(id);
          if (!storedBot.fs) {
            storedBot.localDirectory = null;
          }
          return storedBot;
        })
      );
      if (search?.length) {
        try {
          const params = new URLSearchParams(search);
          const bid = params.get(BOT_SEARCH.BID_KEY);
          if (typeof bid === "string") {
            storedSettings.selected = { id: bid };
          }
        } catch (e) { }
      }
      saveSettings({ ...storedSettings, bots: storedBots });
      setSettingsRestored(true);
    })();
    return () => {
      MainThread.handler = null;
      deleteSearchParam(BOT_SEARCH.BID_KEY);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Search params
  // ------------------------
  useEffect(() => {
    if (!isInitialized) {
      return;
    }
    const { activeBot } = state;
    if (matchPath({ path: "bots", end: false }, pathname) && activeBot) {
      const { id } = activeBot;
      setSearchParam(BOT_SEARCH.BID_KEY, id);
    } else {
      deleteSearchParam(BOT_SEARCH.BID_KEY);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    isInitialized,
    pathname,
    state.activeBot,
    setSearchParam,
    deleteSearchParam,
  ]);

  // Thread tasks
  // ------------------------
  useEffect(() => {
    const { bots } = state;
    const available = isInitialized && isAuthenticated;
    MainThread.handler = available
      ? async () => {
        if (bots) {
          for (const bot of bots) {
            if (!accessBotManage(bot.id) || bot.id === "") {
              continue;
            }

            if (!accessFileSystem(bot)) {
              continue;
            }

            const { bot: sBot, settings } = getBotSettings(bot.id);
            if (await readLocalFiles(sBot)) {
              saveSettings(settings);
            }

            const { localFilesStatus, localFilesStatusFail } = sBot;
            if (
              accessAutoUpload(bot) &&
              (!bot.updateError ||
                !compareLocalFiles(localFilesStatusFail, localFilesStatus))
            ) {
              try {
                await updateBotLocalFiles(bot.id);
                updateMessageDebug({
                  debug: null,
                  fallback: { level: "success", text: "No errors" },
                });
              } catch (error) {
                const { message = t("Something went wrong"), debug } = error;
                dismissAllMessages();
                if (
                  !updateMessageDebug({
                    debug,
                    fallback: { level: "error", text: message },
                  })
                ) {
                  showMessageWithDebug({ error: message, debug });
                }
              }
            }
          }
        }
      }
      : null;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isInitialized, isAuthenticated, state, updateMessageDebug, t]);

  // Parse respose
  // ------------------------
  const parseResponse = async (url, response) => {
    // logger.dev("[BotsContext] parseResponse", url, response);

    const { data } = response;
    if (!data) {
      throw new Error(t("No data after loading"));
    }

    const { bots: responseBots } = data;
    if (!Array.isArray(responseBots)) {
      throw new Error(t("Unsupported response data format"));
    }

    let bots, bot;
    const parsedBots = responseBots
      .map((bot) => {
        const author = bot.author || {};
        const displayName = author.displayName || author.login || t("Unknown");

        const status =
          bot.status ||
          (bot.access === "public" ? BOT_STATUS.PUBLISH : BOT_STATUS.DEV);

        const fileIcon = findFileOpt(bot.files, BOT_FILE_OPTION.ICON);
        const iconSrc = fileIcon?.src && api.fileSrc(fileIcon.src, bot.id);

        return { ...bot, iconSrc, status, author: { ...author, displayName } };
      })
      .sort(
        ({ author: a }, { author: b }) =>
          (b?.uid === user.uid) - (a?.uid === user.uid)
      );

    switch (url) {
      case api.MANAGE.FETCH: {
        bots = parsedBots;
        const activeBot = botById(bots, storedSettings.selected?.id);
        const helpBot = bots.find(({ kind, status }) => kind === BOT_KIND.HELP && status === BOT_STATUS.PUBLISH);
        return { bots, activeBot, helpBot };
      }

      case api.MANAGE.DOWNLOAD:
        bot = parsedBots.shift();
        const { data: base64 } = bot;
        return { base64 };

      case api.MANAGE.CLONE:
      case api.MANAGE.CREATE:
      case api.MANAGE.UPDATE:
        ({ bots } = state);
        bot = parsedBots.shift();
        if (bot) {
          if (botById(bots, bot.id)) {
            bots[bots.indexOf(botById(bots, bot.id))] = bot;
          } else {
            bots.splice(1, 0, bot);
          }
        }
        const activeBot = botById(bots, storedSettings.selected?.id);
        return { bot, bots, activeBot };

      default:
        return null;
    }
  };

  // Restore Session
  // ------------------------
  useEffect(() => {
    (async () => {
      if (!(isInitialized && user)) {
        // waiting for auth
        return;
      }

      if (!settingsRestored || sessionRestored) {
        // waiting for settings, or ression already has been restored
        return;
      }

      try {
        const { bots, activeBot, helpBot } = await fetchBots();
        dispatch({
          type: CMD.INITIALIZE,
          payload: {
            bots,
            loadingError: null,
            activeBot,
            helpBot
          },
        });

        await retrieveFilesSettings(bots);
        setSessionRestored(true);
      } catch (e) {
        logger.error(e);
        dispatch({
          type: CMD.INITIALIZE,
          payload: {
            bots: [],
            loadingError: e,
            activeBot: null,
          },
        });
        setSessionRestored(false);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isInitialized, user, settingsRestored]);

  // Create bot
  // ------------------------
  const createBot = async (props) => {
    const status = BOT_STATUS.DEV;
    const bot = await updateBotLocalFiles("", { ...props, status });
    const { bot: sBot, settings } = await copyBotSettings("", bot.id);
    await readLocalFiles(sBot);
    saveSettings(settings);

    let a = document.querySelectorAll(".qubot-setup-sheck-style");
    for (let i = 0; i < a.length; i++) a[i].checked = false;
    a[0].checked = true;
    return bot;
  };
  // Get License
  // ------------------------
  const getLicense = async (channel, props) => {
    if (channel === BOT_CHANNEL.LIVE_CHAT) {
      return await secureAPIRequest(api.MANAGE.LICENSE, props);
    }
  };

  // Clone bot
  // ------------------------
  const cloneBot = async (id, props) => {
    try {
      const url = api.MANAGE.CLONE;
      const response = await secureAPIRequest(url, {
        bots: [{ id, ...props }],
      });

      const parsedResponse = await parseResponse(url, response);
      const { bot, bots, activeBot } = parsedResponse;

      dispatch({
        type: CMD.BOTS_UPDATED,
        payload: {
          bots,
          activeBot,
        },
      });

      await retrieveFilesSettings(bots);
      return bot;
    } catch (e) {
      logger.error(e);
      throw e;
    }
  };

  // Create / Update / Delete Bot
  // ------------------------
  const updateBot = async (id, props) => {
    try {
      const isCreate = id === "";
      const url = isCreate ? api.MANAGE.CREATE : api.MANAGE.UPDATE;
      const response = await secureAPIRequest(url, {
        bots: [{ id: isCreate ? void 0 : id, ...props }],
      });

      const parsedResponse = await parseResponse(url, response);
      const { bot, bots, activeBot } = parsedResponse;

      dispatch({
        type: CMD.BOTS_UPDATED,
        payload: {
          bots,
          activeBot,
        },
      });

      await retrieveFilesSettings(bots);
      return bot;
    } catch (e) {
      logger.error(e);
      throw e;
    }
  };

  // Update files
  // ------------------------
  const updateBotFiles = async (id, props = {}, files = []) => {
    const isCreate = id === "";
    const { bot: targetBot } = getBotSettings(id);
    const { localFilesStatus, updatePercentTimeout, updateRevision } =
      targetBot;

    const randomPercent = (max) => {
      return (max * 0.3 + Math.random() * (max * 0.7)) | 0;
    };

    let percentInterval;
    let fakePercent = 20 + randomPercent(10);

    try {
      if (!(files.length || isCreate)) {
        setBotParam(id, {
          isUpdating: false,
          updateError: null,
          updateErrorDebug: null,
          updatePercent: updatePercentTimeout ? targetBot.updatePercent : 0,
          localFilesStatusFail: null,
        });
        return;
      }

      setBotParam(id, {
        isUpdating: true,
        updateError: null,
        updateErrorDebug: null,
        updatePercent: fakePercent,
        updatePercentTimeout: 0,
        localFilesStatusFail: null,
      });

      percentInterval = setInterval(
        () =>
          setBotParam(id, {
            updatePercent: Math.min((fakePercent += randomPercent(30)), 99),
          }),
        1000
      );

      const url = isCreate ? api.MANAGE.CREATE : api.MANAGE.UPDATE;
      const response = await secureAPIRequest(url, {
        bots: [{ id: isCreate ? void 0 : id, files, ...props }],
      });

      clearInterval(percentInterval);
      const parsedResponse = await parseResponse(url, response);
      const { bot, bots, activeBot } = parsedResponse;

      setBotParam(id, {
        isUpdating: false,
        updateError: null,
        updateErrorDebug: null,
        updatePercent: 100,
        updatePercentTimeout: 0,
        updateRevision: (updateRevision || 0) + 1,
      });

      if (updatePercentTimeout) {
        clearTimeout(updatePercentTimeout);
      }

      setBotParam(id, {
        updatePercentTimeout: setTimeout(() => {
          const { bot: sBot = {} } = getBotSettings(id);
          if (!(sBot.isUpdating || sBot.updateError || id === "")) {
            setBotParam(id, {
              updatePercent: 0,
              updatePercentTimeout: 0,
            });
          }
        }, 3000),
      });

      dispatch({
        type: CMD.BOTS_UPDATED,
        payload: {
          bots,
          activeBot,
        },
      });

      await retrieveFilesSettings(bots);
      // showMessage({ save: t("Bot files updated") })
      return bot;
    } catch (error) {
      logger.error(error);
      const { debug } = error;
      clearInterval(percentInterval);
      setBotParam(id, {
        isUpdating: false,
        updateError: error,
        updateErrorDebug: debug,
        updatePercent: 100,
        localFilesStatusFail: localFilesStatus,
      });
      //showMessageWithDebug({ error: "Error 123" })
      throw error;
    }
  };

  // Update bot local files (FileSystem)
  // ------------------------
  const updateBotLocalFiles = async (id, props = {}) => {
    try {
      const { bot: targetBot } = getBotSettings(id);
      const { localFilesStatus } = targetBot;
      const files = localFilesStatus
        ? await Promise.all(
          (localFilesStatus || [])
            .filter(
              (file) => !!file.source && file.status !== BOT_FILE_STATUS.IDLE
            )
            .map(async ({ source, option, status, mtime }) => {
              const { fullPath: path } = source;
              if (status === BOT_FILE_STATUS.REMOVE) {
                return { path, delete: true };
              }
              const buffer = await source.arrayBuffer();
              const data = await arrayBufferToBase64Async(buffer);
              const file = {
                path,
                data,
                mtime: mtime?.getTime(),
              };
              if (
                option === BOT_FILE_OPTION.MAIN ||
                option === BOT_FILE_OPTION.ICON ||
                option === BOT_FILE_OPTION.STYLE
              ) {
                file.type = option;
                if (option === BOT_FILE_OPTION.MAIN) file.main = true; // deprecated
              }
              return file;
            })
        )
        : [];

      return await updateBotFiles(id, props, files);
    } catch (error) {
      logger.error(error);
      throw error;
    }
  };

  // Delete Bot
  // ------------------------
  const deleteBot = async (id) => {
    const { bots } = state;
    const bot = botById(bots, id);
    const { bundle } = bot;

    await updateBot(id, { bundle, delete: true });
    await removeBotSettings(id);

    const found = botById(bots, id);
    if (found) {
      bots.splice(bots.indexOf(found), 1);
      dispatch({
        type: CMD.BOTS_UPDATED,
        payload: {
          bots,
          activeBot: null,
        },
      });
    }
  };

  // Refresh bots
  // ------------------------
  const refreshBots = async () => {
    try {
      dispatch({ type: CMD.REFRESH_START });
      const { bots, activeBot, helpBot } = await fetchBots();
      dispatch({
        type: CMD.REFRESH_END,
        payload: {
          bots,
          activeBot,
          helpBot,
          loadingError: null,
        },
      });

      await retrieveFilesSettings(bots);
    } catch (e) {
      logger.error(e);
      const { bots, activeBot } = state;
      dispatch({
        type: CMD.REFRESH_END,
        payload: {
          bots,
          activeBot,
          loadingError: e,
        },
      });
    }
  };

  // Download bot
  // ------------------------
  const downloadBot = async (id) => {
    if (id === void 0 || id === null) {
      return null;
    }
    const response = await secureAPIRequest(api.MANAGE.DOWNLOAD, {
      bots: [{ id }],
    });
    const { base64 } = await parseResponse(api.MANAGE.DOWNLOAD, response);
    const bot = botById(state.bots, id);
    const fileName = bot ? `${bot.name}` : `${id}`;
    downloadBase64File(base64, `${fileName}.zip`, "application/zip");
  };

  // Check access base
  // ------------------------
  const accessBotBase = useCallback(
    (id) => {
      return !!(isInitialized && typeof id === "string");
    },
    [isInitialized]
  );

  // Check access author
  // ------------------------
  const accessBotAuthor = useCallback(
    (id, bots) => {
      if (id === void 0 || id === null) {
        return false;
      }
      if (typeof id === "object") {
        ({ id } = id);
      }
      const { uid } = user || {};
      const bot = botById(bots || state.bots, id);
      const { author } = bot || {};
      return !!(accessBotBase(id) && author && uid && author.uid === uid);
    },
    [accessBotBase, user, state.bots]
  );

  // Check bot is yml
  // ------------------------
  const isYMLBot = useCallback(
    (id, bots) => {
      if (id === void 0 || id === null) {
        return false;
      }
      if (typeof id === "object") {
        ({ id } = id);
      }
      const bot = botById(bots || state.bots, id);
      const file = findFileOpt(bot?.files, BOT_FILE_OPTION.MAIN);
      return !!(file && REGEXP_YML.test(file.path));
    },
    [state.bots]
  );

  // Check bot is WordPress
  // ------------------------
  const isWordPressBot = useCallback(
    (id, bots) => {
      if (id === void 0 || id === null) {
        return false;
      }
      if (typeof id === "object") {
        ({ id } = id);
      }
      const bot = botById(bots || state.bots, id);
      return bot?.channels?.find((channel) => channel.name === "wordpress");
    },
    [state.bots]
  );

  // Check access play
  // ------------------------
  const accessBotPlay = useCallback(
    (id, bots) => {
      if (id === void 0 || id === null) {
        return false;
      }
      if (typeof id === "object") {
        ({ id } = id);
      }
      return !accessBotAuthor(id, bots) && !isYMLBot(id, bots);
    },
    [accessBotAuthor, isYMLBot]
  );

  // Check access clone
  // ------------------------
  const accessBotClone = useCallback(
    (id, bots) => {
      if (id === void 0 || id === null) {
        return false;
      }
      if (typeof id === "object") {
        ({ id } = id);
      }
      return !accessBotAuthor(id, bots) && !isYMLBot(id, bots);
    },
    [accessBotAuthor, isYMLBot]
  );

  // Check access manage
  // ------------------------
  const accessBotManage = useCallback(
    (id, bots) => {
      if (id === void 0 || id === null) {
        return false;
      }
      if (typeof id === "object") {
        ({ id } = id);
      }

      return accessBotAuthor(id, bots) || checkPermission(api.MANAGE.FETCH) || checkPermission(api.MANAGE.UPDATE);
    },
    [accessBotAuthor, checkPermission]
  );

  // Check access manage
  // ------------------------
  const accessBotManageFiles = useCallback((id, bots) => {
    return false;
    // return accessBotManage(id, bots);
  }, []);

  // Check access testing
  // ------------------------
  const accessBotTesting = (bot) => {
    return false;
    // const { id } = bot || {};
    // return accessBotBase(id);
  };

  // Check access editor
  // ------------------------
  const accessBotEditor = (bot) => {
    const { id } = bot || {};
    return accessBotBase(id) && !isYMLBot(id) && !isWordPressBot(id) || checkPermission(api.MANAGE.FETCH) || checkPermission(api.MANAGE.UPDATE);
  };

  // Check access setup
  // ------------------------
  const accessBotSetup = (id, bots) => {
    if (id === void 0 || id === null) {
      return false;
    }
    if (typeof id === "object") {
      ({ id } = id);
    }
    return (accessBotAuthor(id, bots) && !isYMLBot(id, bots) && !isWordPressBot(id, bots)) || checkPermission(api.MANAGE.UPDATE);
  };

  // Check access channels
  // ------------------------
  const accessBotChannels = (bot) => {
    const { id, kind } = bot || {};
    return (
      accessBotBase(id) &&
      (accessBotAuthor(id) ||
        typeof kind !== "string" ||
        kind === BOT_KIND.USER)
    ) || checkPermission(api.MANAGE.FETCH);
  };

  // Check access analytics
  // ------------------------
  const accessBotAnalytics = (bot) => {
    const { id, kind } = bot || {};
    return (
      accessBotBase(id) &&
      (accessBotAuthor(id) ||
        typeof kind !== "string" ||
        kind === BOT_KIND.USER)
    ) || checkPermission(api.MANAGE.FETCH);
  };

  // Check access data
  // ------------------------
  const accessBotData = (bot) => {
    const { id, kind } = bot || {};
    return (
      accessBotBase(id) &&
      (accessBotAuthor(id) ||
        typeof kind !== "string" ||
        kind === BOT_KIND.USER)
    );
  };

  // Check auto upload available
  // ------------------------
  const accessAutoUpload = (bot) => {
    return (
      accessFileSystem(bot) &&
      bot.id === state.activeBot?.id &&
      bot.uploadMode === "auto"
    );
  };

  // Check file system available
  // ------------------------
  const accessFileSystem = (bot) =>
    isInitialized &&
    user &&
    bot &&
    bot.fs &&
    bot.localDirectory &&
    bot.hasPermissions &&
    !bot.readdirError;

  // Check chat gpt settings
  // ------------------------
  const accessChatGPT = (id, bots) => {
    if (id === void 0 || id === null) {
      return false;
    }
    if (typeof id === "object") {
      ({ id } = id);
    }
    return (accessBotAuthor(id, bots) && !isYMLBot(id, bots) && !isWordPressBot(id, bots)) || checkPermission(api.MANAGE.UPDATE);
  };
  // Check Is chat gpt bot?
  // ------------------------
  const isChatGPTBot = (id, bot) => {
    if (id === void 0 || id === null) {
      return false;
    }
    if (typeof id === "object") {
      bot = id;
      ({ id } = id);
    }
    return (bot && bot.bot_type && bot.bot_type === 'gpt')
  };
  // Check assistant settings
  // ------------------------
  const accessAssistant = (id, bots) => {
    if (id === void 0 || id === null) {
      return false;
    }
    if (typeof id === "object") {
      ({ id } = id);
    }
    return (accessBotAuthor(id, bots) && !isYMLBot(id, bots) && !isWordPressBot(id, bots)) || checkPermission(api.MANAGE.UPDATE);
  };

  // Get bot access levels
  // ------------------------
  const accessBotLevels = (bot) => {
    return {
      play: accessBotPlay(bot),
      clone: accessBotClone(bot),
      manage: accessBotManage(bot),
      settings: accessBotManage(bot),
      files: accessBotManageFiles(bot),
      delete: accessBotManage(bot),
      testing: accessBotTesting(bot),
      editor: accessBotEditor(bot),
      setup: accessBotSetup(bot),
      channels: accessBotChannels(bot),
      analytics: accessBotAnalytics(bot),
      data: accessBotData(bot),
      fileSystem: accessFileSystem(bot),
      autoUpload: accessAutoUpload(bot),
      new_source: accessChatGPT(bot),
      sources: accessChatGPT(bot),
      gptsettings: accessChatGPT(bot),
      gptbot: isChatGPTBot(bot),
      assistant: accessAssistant(bot),
    };
  };

  // Get param
  // ------------------------
  const getParam = (name, defaultValue) => {
    if (name === void 0 || name === null) {
      return;
    }
    // logger.dev(`[BotsContext] getParam "${name}"`);
    const { settings } = state;
    return name in settings ? settings[name] : defaultValue;
  };

  // Set param
  // ------------------------
  const setParam = (name, value) => {
    if (name === void 0 || name === null) {
      return;
    }
    // logger.dev(`[BotsContext] setParam "${name}" "${value}"`);
    const { settings } = state;
    if (settings[name] !== value) {
      settings[name] = value;
      saveSettings(settings);
    }
  };

  // Set bot active
  // ------------------------
  const setBotActive = (id) => {
    // logger.dev(`[BotsContext] setBotActive "${id}"`);
    const { bots, settings } = state;
    const activeBot = botById(bots, id);
    let { recentIDs = [] } = settings;
    if (id) {
      if (recentIDs.indexOf(id) > -1) {
        recentIDs.splice(recentIDs.indexOf(id), 1);
      }
      recentIDs.unshift(id);
      if (recentIDs.length > 100) {
        recentIDs.length = 100;
      }
    }
    saveSettings({ ...settings, selected: { id }, recentIDs });
    dispatch({
      type: CMD.SET_ACTIVE,
      payload: {
        activeBot,
      },
    });
  };

  // Get bot param custom
  // ------------------------
  const getBotParam = (id, name, defaultValue) => {
    if (id === void 0 || id === null) {
      return;
    }
    const { bot } = getBotSettings(id);
    return name in bot ? bot[name] : defaultValue;
  };

  // Set bot param custom
  // ------------------------
  const setBotParam = (id, props, options) => {
    if (id === void 0 || id === null) {
      return;
    }
    const { bot, settings } = getBotSettings(id);
    let modified;
    for (let key in props) {
      if (bot[key] !== props[key]) {
        bot[key] = props[key];
        modified = true;
      }
    }
    if (modified && !options?.dirty) {
      saveSettings(settings);
    }
  };

  // Setup bot file
  // ------------------------
  const setBotFileParam = async (id, path, props, options) => {
    if (id === void 0 || id === null || !path) {
      return;
    }

    const { option } = props || {};
    const { dirty } = options || {};
    if (!option) {
      return;
    }

    const { bot: sBot, settings } = getBotSettings(id);
    const { setupFiles, file } = getBotFileSetup(sBot, path);

    if (option) {
      setupFiles.forEach((file) => {
        if (file.option === option) {
          delete file.option;
        }
      });
      file.option = option;
    }

    await readLocalFiles(sBot);
    if (!dirty) saveSettings(settings);
  };

  // Reset bot settings
  // ------------------------
  const resetBotSettings = async (id, props = {}) => {
    if (id === void 0 || id === null) {
      return;
    }
    // logger.dev("[BotsContext] resetBotSettings", id);
    const { bot: sBot, settings } = getBotSettings(id);
    if (sBot) {
      for (let key in sBot) key !== "id" && (sBot[key] = null);
      for (let key in props) sBot[key] = props[key];
      saveSettings(settings);
    }
    await BotFileSystem.forgetDirectory(id);
  };

  // Remove bot settings
  // ------------------------
  const removeBotSettings = async (id) => {
    if (id === void 0 || id === null) {
      return;
    }
    // logger.dev("[BotsContext] removeBotSettings", id);
    const { bot: sBot, settings } =
      getBotSettings(id, { readOnly: true }) || {};
    if (sBot) {
      const { bots: sBots } = settings;
      sBots.splice(sBots.indexOf(sBot), 1);
      saveSettings(settings);
    }
    await BotFileSystem.forgetDirectory(id);
  };

  // Choose bot localDirectory
  // ------------------------
  const chooseBotDirectory = async (id) => {
    if (id === void 0 || id === null) {
      return;
    }

    // logger.dev("[BotsContext] chooseBotDirectory", id);
    const fs = await BotFileSystem.selectDirectory(id);
    const { bot: sBot, settings } = getBotSettings(id);
    sBot.fs = fs;
    await readLocalFiles(sBot);
    saveSettings(settings);
  };

  // Request bot localFiles
  // ------------------------
  const requestLocalFiles = async (id) => {
    if (id === void 0 || id === null) {
      return;
    }
    const { bot: sBot, settings } = getBotSettings(id);
    const { fs } = sBot || {};
    if (fs) {
      await requestPermissions(sBot);
      await readLocalFiles(sBot);
      saveSettings(settings);
    }
  };

  // Generate bot bundle
  // ------------------------
  const generateBundle = (name) => {
    if (user) {
      const url = user.websiteURL || "";
      const hostname = (url.length > 0) ? new URL(url).hostname : "";
      const domainname = hostname.split(".").reverse().join(".");
      const sep = name ? "." : "";
      const postfix = (name || "")
        .replace(/[\s-]/gi, "_")
        .replace(/[^a-zA-z0-9_]/gi, "");
      return `${domainname}${sep}${postfix.toLowerCase()}`;
    }
  };

  // Qubot type
  // ------------------------
  const qubotType = useCallback(
    (bot) => {
      const { kind, author } = bot || {};
      return author?.uid === user?.uid
        ? "MyBots"
        : kind === BOT_KIND.TEMPLATE
          ? "Templates"
          : kind === BOT_KIND.EXAMPLE
            ? "Examples"
            : "SharedBots";
    },
    [user]
  );

  const availableFilter = () => {
    const { uid, license } = user;
    const filters = {
      author: { author: { uid } },
      public: { kind: BOT_KIND.USER, access: "public" },
      templates: { kind: BOT_KIND.TEMPLATE, access: "public" },
      examples: { kind: BOT_KIND.EXAMPLE, access: "public" },
      assistant: { kind: BOT_KIND.HELP, status: BOT_STATUS.PUBLISH },
    };
    const defFilter = [filters.author, filters.templates, filters.examples, filters.assistant].concat(isExperimental() ? [filters.public] : []);

    if (license?.length > 0) {
      for (let i = 0; i < license?.length; i++) {
        if (!license[i].bots && license[i].roles.find((item) => item.name === "Super Admin")) return [];
        if (license[i].bots) {
          for (let j = 0; j < license[i].bots.length; j++) {
            defFilter.push({ id: license[i].bots[j] })
          }
        }
      }
      return defFilter;
    }

    return defFilter;
  }

  // Rest
  // ------------------------
  const fetchBots = async () => {
    const { uid } = user;

    const response = await secureAPIRequest(api.MANAGE.FETCH, {
      filter: availableFilter(),
    });
    const { bots, activeBot, helpBot } = await parseResponse(api.MANAGE.FETCH, response);
    const newBot = { id: "", author: { uid }, version: "1.0.0" };
    bots.unshift(newBot);
    bots.unshift(newBot);
    return { bots, activeBot, helpBot };
  };

  const requestPermissions = async (sBot) => {
    const { fs } = sBot || {};
    if (fs) {
      await fs.requestPermissions("read");
    }
  };

  const readLocalFiles = async (sBot) => {
    const { id, fs } = sBot || {};
    if (id === void 0 || id === null) {
      return false;
    }

    let localDirectory = null;
    let localFiles = [];
    let localFilesStatus = [];
    let hasPermissions = false;
    let readdirError = null;

    if (fs) {
      localDirectory = fs.fullPath;
      hasPermissions = await fs.hasPermissions("read");

      if (hasPermissions) {
        try {
          const fsFiles = await fs.readdir("", { recursive: true });
          localFiles = await Promise.all(
            fsFiles.map(async (file) => await file.stats())
          );
          const bot = botById(state.bots, id);
          localFiles = localFiles.filter(
            ({ size, source }) => size > 0 && !/^\./.test(source?.fullPath)
          );
          localFilesStatus = parseLocalFiles(bot, sBot, localFiles);
        } catch (error) {
          logger.error(error);
          readdirError = error;
        }
      }
    }

    if (compareLocalFiles(sBot?.localFilesStatus, localFilesStatus)) {
      return false;
    }

    sBot.localDirectory = localDirectory;
    sBot.localFiles = localFiles;
    sBot.localFilesStatus = localFilesStatus;
    sBot.hasPermissions = hasPermissions;
    sBot.readdirError = readdirError;
    return true;
  };

  const getBotSettings = (id, options) => {
    const { settings } = state;
    const { bots: sBots } = settings;
    const sBot = botById(sBots, id);
    if (sBot) {
      return { settings, bot: sBot };
    }
    return options?.readOnly ? null : createBotSettings(id);
  };

  const createBotSettings = (id, props) => {
    const { settings } = state;
    let { bots: sBots } = settings;
    if (!sBots) {
      sBots = settings.bots = [];
    }
    const sBot = props ? { id, ...props } : { id };
    sBots.push(sBot);
    setStoredSettings(settings);
    return { settings, bot: sBot };
  };

  const copyBotSettings = async (target, id) => {
    if (target === void 0 || target === null || id === void 0 || id === null) {
      return;
    }
    const { bot: targetBot } = getBotSettings(target);
    const { bot: sBot, settings } = getBotSettings(id);
    if (targetBot && sBot) {
      for (let key in targetBot) {
        if (key !== "id") sBot[key] = targetBot[key];
        if (key === "fs")
          await BotFileSystem.storeDirectory(id, targetBot[key]);
      }
      saveSettings(settings);
      return { bot: sBot, settings };
    }
  };

  const retrieveFilesSettings = async (value) => {
    const { settings } = state;
    const retrieve = async ({ id, files }) => {
      if (accessBotManage(id, Array.isArray(value) ? value : null))
        return await Promise.all(
          (files || []).map(async ({ path, type: option }) => {
            if (!!option) {
              return await setBotFileParam(
                id,
                path,
                { option },
                { dirty: true }
              );
            }
          })
        );
    };

    if (Array.isArray(value)) {
      await Promise.all(value.map(async (bot) => await retrieve(bot)));
    } else {
      await retrieve(value);
    }

    saveSettings(settings);
  };

  const saveSettings = (settings) => {
    const { bots: sBots, selected, recentIDs } = settings;
    setStoredSettings({
      bots: sBots.map(({ id, uploadMode, localDirectory }) => ({
        id,
        uploadMode,
        localDirectory,
      })),
      selected,
      recentIDs,
    });
    dispatch({
      type: CMD.SET_SETTINGS,
      payload: {
        settings,
      },
    });
  };

  return (
    <BotsContext.Provider
      value={{
        ...state,

        createBot,
        cloneBot,
        updateBot,
        updateBotFiles,
        updateBotLocalFiles,
        deleteBot,
        refreshBots,
        downloadBot,
        generateBundle,
        accessBotLevels,
        qubotType,

        getParam,
        setParam,
        setBotActive,

        getBotParam,
        setBotParam,
        setBotFileParam,
        resetBotSettings,

        chooseBotDirectory,
        requestLocalFiles,
        getLicense,
      }}
    >
      {children}
    </BotsContext.Provider>
  );
}

export {
  BotsProvider,
  BotsContext,
  BOT_KIND,
  BOT_STATUS,
  BOT_SEARCH,
  BOT_CHANNEL,
  BOT_MODULE,
  BOT_GPT_MODEL,
  BOT,
  BOT_CATEGORY,
  BOT_CATEGORY_LIST,
  BOT_FILE_OPTION,
  BOT_FILE_STATUS,
  BOT_FILE_GROUP,
};
