import { Formik } from "formik";
import {
    Button,
    Card,
    Form,
    Popover,
    OverlayTrigger
} from "react-bootstrap";

import {
    HelpCircle
} from "react-feather";

import React, { useEffect, useState } from "react";
import { BOT_MODULE, BOT_GPT_MODEL } from "../../../contexts/bots/BotsContext";
import useBots from "../../../hooks/useBots";
import * as Yup from "yup";
import useMessage from "../../../hooks/useMessage";
import { useTranslation } from "../../../hooks/useLocalization";

import useQubot from "../../../hooks/useQubot";

import useAnalytics from "../../../hooks/useAnalytics";

const GPTSettings = () => {
    const { t } = useTranslation();
    const { showMessage, showMessageWithDebug } = useMessage();
    const { activeBot, updateBot, qubotType } = useBots();
    const { id, lang, modules = [] } = activeBot || {};
    const gpt_modules = modules.find(({ name }) => name === 'chat_gpt') || {};

    const [maxSystem, setMaxSystem] = useState(1024)
    const [maxPrompt, setMaxPrompt] = useState(256)
    const tokensToAnswer = 12

    const qubot = useQubot();
    const type = qubotType(activeBot);
    const { CONSOLE_EVENTS } = useAnalytics();

    useEffect(() => {
        qubot?.showChat({ botID: id, type }, { start: false, lang: lang });
    }, [qubot, id, lang, type]);

    useEffect(() => {
        return () => !qubot?.isPageHasChat() && qubot?.hideChat();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Formik
            enableReinitialize
            initialValues={{
                //key: "",
                model: gpt_modules.model || "gpt-3.5-turbo",
                temperature: gpt_modules.temperature || 0.30,
                top_p: gpt_modules.top_p || 1.00,
                max_tokens: gpt_modules.max_tokens || 1024,
                presence_penalty: gpt_modules.presence_penalty || 0.0,
                frequency_penalty: gpt_modules.frequency_penalty || 0.0,
                count_generations: gpt_modules.count_generations || 1,
                system: gpt_modules.system || "",
                prompt: gpt_modules.prompt || "",
                use_model_data: gpt_modules.use_model_data || false,
                no_doc_answer: gpt_modules.no_doc_answer || "",
                match_threshold: gpt_modules.match_threshold || 0.50,
                documents_count: gpt_modules.documents_count || 5,
                only_data_strong: gpt_modules.only_data_strong || 0.99,
                chat_history: gpt_modules.chat_history || 3
            }}
            validationSchema={Yup.object().shape({
                system: Yup.string().max(maxSystem - tokensToAnswer),
                prompt: Yup.string().max((maxPrompt > 0) ? maxPrompt : 0),
            })}
            onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
                let isSubmitted = true
                try {
                    setSubmitting(true);
                    await updateBot(id, {
                        modules: [
                            { ...values, name: BOT_MODULE.CHATGPT, },
                        ],
                    });
                    await CONSOLE_EVENTS.EV_GPTSettings.send({ action: 'update', params: { ...values } })
                } catch (error) {
                    const { message = t("Something went wrong"), debug } = error;
                    showMessageWithDebug({ error: message, debug });
                    isSubmitted = false
                } finally {
                    if (isSubmitted) {
                        showMessage({ save: "" });
                    }
                    setSubmitting(false);
                }
            }}
        >
            {({
                errors,
                handleBlur,
                handleChange,
                handleSubmit,
                isSubmitting,
                touched,
                values,
            }) => (

                <Form onSubmit={handleSubmit}>
                    {/* {errors.submit && (
                    <Alert className="align-items-center" variant="danger">
                        <div className="alert-message">{errors.submit}</div>
                    </Alert>
                    )} */}
                    {setMaxSystem(BOT_GPT_MODEL.getMaxTokens(values.model))}
                    {setMaxPrompt(BOT_GPT_MODEL.getMaxTokens(values.model) - tokensToAnswer - values.system.length)}
                    <Card style={{ maxWidth: "800px" }}>
                        <Card.Header>
                            <Card.Title tag="h5" className="mb-0">
                                {t("GPT Assistant Settings")}
                            </Card.Title>
                        </Card.Header>
                        <Card.Body>

                            <Form.Group className="mb-3">
                                <Form.Label htmlFor="system" className="d-flex align-items-center" >
                                    {t("GPT System Message")}
                                    <OverlayTrigger placement='right'
                                        overlay={
                                            <Popover className="clearPopover" id={`popover-positioned-bottom`}>
                                                <Popover.Body style={{ cursor: 'default' }}>{t("GPT System Hint")}</Popover.Body>
                                            </Popover>
                                        }
                                    >
                                        <HelpCircle className="icon-hover" style={{ cursor: 'help', marginLeft: '.5em', opacity: '.85' }} size={18}></HelpCircle>
                                    </OverlayTrigger>
                                </Form.Label>
                                <Form.Control
                                    as="textarea"
                                    id="system"
                                    type="text"
                                    name="system"
                                    placeholder={t("GPT System Placeholder")}
                                    value={values.system}
                                    isInvalid={Boolean(
                                        touched.system && errors.system
                                    )}
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                />
                                {!!touched.system && (
                                    <Form.Control.Feedback type="invalid">
                                        {errors.system}
                                    </Form.Control.Feedback>
                                )}
                            </Form.Group>

                            <Form.Group className="mb-3">
                                <Form.Label htmlFor="prompt" className="d-flex align-items-center" >
                                    {t("GPT Prompt Message")}
                                    <OverlayTrigger placement='right'
                                        overlay={
                                            <Popover className="clearPopover" id={`popover-positioned-bottom`}>
                                                <Popover.Body style={{ cursor: 'default' }}>{t("GPT Prompt Hint")}</Popover.Body>
                                            </Popover>
                                        }
                                    >
                                        <HelpCircle className="icon-hover" style={{ cursor: 'help', marginLeft: '.5em', opacity: '.85' }} size={18}></HelpCircle>
                                    </OverlayTrigger>
                                </Form.Label>
                                <Form.Control
                                    as="textarea"
                                    id="prompt"
                                    type="text"
                                    name="prompt"
                                    placeholder={t("GPT Prompt Placeholder")}
                                    value={values.prompt}
                                    isInvalid={Boolean(
                                        touched.prompt && errors.prompt
                                    )}
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                />
                                {!!touched.prompt && (
                                    <Form.Control.Feedback type="invalid">
                                        {errors.prompt}
                                    </Form.Control.Feedback>
                                )}
                            </Form.Group>

                            <Form.Group className="mb-3">
                                <Form.Label htmlFor="chat_history" className="d-flex align-items-center" >
                                    {t("GPT Chat History")}
                                    <OverlayTrigger placement='right'
                                        overlay={
                                            <Popover className="clearPopover" id={`popover-positioned-bottom`}>
                                                <Popover.Body style={{ cursor: 'default' }}>{t("GPT Chat History Hint")}</Popover.Body>
                                            </Popover>
                                        }
                                    >
                                        <HelpCircle className="icon-hover" style={{ cursor: 'help', marginLeft: '.5em', opacity: '.85' }} size={18}></HelpCircle>
                                    </OverlayTrigger>
                                </Form.Label>
                                <div style={{ display: 'flex' }}>
                                    <Form.Range
                                        id="chat_history"
                                        name="chat_history"
                                        value={values.chat_history}
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        min={0}
                                        max={10}
                                        step={1}
                                    />
                                    <Form.Label htmlFor="chat_history" style={{ minWidth: '40px', textAlign: 'center' }}>{values.chat_history}</Form.Label>
                                </div>
                                {!!touched.chat_history && (
                                    <Form.Control.Feedback type="invalid">
                                        {errors.chat_history}
                                    </Form.Control.Feedback>
                                )}
                            </Form.Group>

                        </Card.Body>
                    </Card>

                    <Card style={{ maxWidth: "800px" }}>
                        <Card.Header>
                            <Card.Title tag="h5" className="mb-0">
                                {t("GPT Sources Settings")}
                            </Card.Title>
                        </Card.Header>
                        <Card.Body>
                            <Form.Group className="mb-3">
                                <Form.Label htmlFor="documents_count" className="d-flex align-items-center" >
                                    {t("GPT Documents count")}
                                    <OverlayTrigger placement='right'
                                        overlay={
                                            <Popover className="clearPopover" id={`popover-positioned-bottom`}>
                                                <Popover.Body style={{ cursor: 'default' }}>{t("GPT Documents count Hint")}</Popover.Body>
                                            </Popover>
                                        }
                                    >
                                        <HelpCircle className="icon-hover" style={{ cursor: 'help', marginLeft: '.5em', opacity: '.85' }} size={18}></HelpCircle>
                                    </OverlayTrigger>
                                </Form.Label>
                                <div style={{ display: 'flex' }}>
                                    <Form.Range
                                        id="documents_count"
                                        //type="number"
                                        name="documents_count"
                                        value={values.documents_count}
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        min={0}
                                        max={10}
                                        step={1}
                                    />
                                    <Form.Label htmlFor="documents_count" style={{ minWidth: '40px', textAlign: 'center' }}>{values.documents_count}</Form.Label>
                                </div>
                                {!!touched.documents_count && (
                                    <Form.Control.Feedback type="invalid">
                                        {errors.documents_count}
                                    </Form.Control.Feedback>
                                )}
                            </Form.Group>

                            <Form.Group className="mb-3">
                                <Form.Label htmlFor="match_threshold" className="d-flex align-items-center" >
                                    {t("GPT Match threshold")}
                                    <OverlayTrigger placement='right'
                                        overlay={
                                            <Popover className="clearPopover" id={`popover-positioned-bottom`}>
                                                <Popover.Body style={{ cursor: 'default' }}>{t("GPT Match threshold Hint")}</Popover.Body>
                                            </Popover>
                                        }
                                    >
                                        <HelpCircle className="icon-hover" style={{ cursor: 'help', marginLeft: '.5em', opacity: '.85' }} size={18}></HelpCircle>
                                    </OverlayTrigger>
                                </Form.Label>
                                <div style={{ display: 'flex' }}>
                                    <Form.Range
                                        id="match_threshold"
                                        name="match_threshold"
                                        value={values.match_threshold}
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        min={.01}
                                        max={0.99}
                                        step={0.01}
                                        disabled={values.documents_count === 0}
                                    />
                                    <Form.Label htmlFor="match_threshold" style={{ minWidth: '40px', textAlign: 'center' }}>{values.match_threshold}</Form.Label>
                                </div>
                                {!!touched.match_threshold && (
                                    <Form.Control.Feedback type="invalid">
                                        {errors.match_threshold}
                                    </Form.Control.Feedback>
                                )}
                            </Form.Group>

                            <Form.Group className="mb-3">
                                <Form.Label htmlFor="use_model_data" className="d-flex align-items-center" >
                                    {t("GPT Use model data")}
                                    <OverlayTrigger placement='right'
                                        overlay={
                                            <Popover className="clearPopover" id={`popover-positioned-bottom`}>
                                                <Popover.Body style={{ cursor: 'default' }}>{t("GPT Use model data Hint")}</Popover.Body>
                                            </Popover>
                                        }
                                    >
                                        <HelpCircle className="icon-hover" style={{ cursor: 'help', marginLeft: '.5em', opacity: '.85' }} size={18}></HelpCircle>
                                    </OverlayTrigger>
                                </Form.Label>
                                <Form.Switch
                                    id="use_model_data"
                                    name="use_model_data"
                                    checked={values.use_model_data}
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                />
                                {!!touched.system && (
                                    <Form.Control.Feedback type="invalid">
                                        {errors.system}
                                    </Form.Control.Feedback>
                                )}
                            </Form.Group>

                            <Form.Group className="mb-3">
                                <Form.Label htmlFor="no_doc_answer" className="d-flex align-items-center" >
                                    {t("GPT No doc answer")}
                                    <OverlayTrigger placement='right'
                                        overlay={
                                            <Popover className="clearPopover" id={`popover-positioned-right`}>
                                                <Popover.Body style={{ cursor: 'default' }}>{t("GPT No doc answer Hint")}</Popover.Body>
                                            </Popover>
                                        }
                                    >
                                        <HelpCircle className="icon-hover" style={{ cursor: 'help', marginLeft: '.5em', opacity: '.85' }} size={18}></HelpCircle>
                                    </OverlayTrigger>
                                </Form.Label>
                                <Form.Control
                                    as="textarea"
                                    id="no_doc_answer"
                                    type="text"
                                    name="no_doc_answer"
                                    placeholder={t("GPT No doc answer Placeholder")}
                                    value={values.no_doc_answer}
                                    isInvalid={Boolean(
                                        touched.no_doc_answer && errors.no_doc_answer
                                    )}
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    disabled={!values.use_model_data}
                                />
                                {!!touched.system && (
                                    <Form.Control.Feedback type="invalid">
                                        {errors.system}
                                    </Form.Control.Feedback>
                                )}
                            </Form.Group>

                            <Form.Group className="mb-3">
                                <Form.Label htmlFor="only_data_strong" className="d-flex align-items-center" >
                                    {t("GPT No doc answer strong")}
                                    <OverlayTrigger placement='right'
                                        overlay={
                                            <Popover className="clearPopover" id={`popover-positioned-bottom`}>
                                                <Popover.Body style={{ cursor: 'default' }}>{t("GPT No doc answer strong Hint")}</Popover.Body>
                                            </Popover>
                                        }
                                    >
                                        <HelpCircle className="icon-hover" style={{ cursor: 'help', marginLeft: '.5em', opacity: '.85' }} size={18}></HelpCircle>
                                    </OverlayTrigger>
                                </Form.Label>
                                <div style={{ display: 'flex' }}>
                                    <Form.Range
                                        id="only_data_strong"
                                        name="only_data_strong"
                                        value={values.only_data_strong}
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        min={.01}
                                        max={0.99}
                                        step={0.01}
                                        disabled={!values.use_model_data}
                                    />
                                    <Form.Label htmlFor="only_data_strong" style={{ minWidth: '40px', textAlign: 'center' }}>{values.only_data_strong}</Form.Label>
                                </div>
                                {!!touched.only_data_strong && (
                                    <Form.Control.Feedback type="invalid">
                                        {errors.only_data_strong}
                                    </Form.Control.Feedback>
                                )}
                            </Form.Group>

                        </Card.Body>
                    </Card>

                    <Card style={{ maxWidth: "800px" }}>
                        <Card.Header>
                            <Card.Title tag="h5" className="mb-0">{t("GPT Model Settings")}</Card.Title>
                        </Card.Header>
                        <Card.Body>

                            <Form.Group className="mb-3">
                                <Form.Label htmlFor="model" className="d-flex align-items-center" >
                                    {t("GPT Model")}
                                    <OverlayTrigger placement='right'
                                        overlay={
                                            <Popover className="clearPopover" id={`popover-positioned-bottom`}>
                                                <Popover.Body style={{ cursor: 'default' }}>{t("GPT Model Hint")}</Popover.Body>
                                            </Popover>
                                        }
                                    >
                                        <HelpCircle className="icon-hover" style={{ cursor: 'help', marginLeft: '.5em', opacity: '.85' }} size={18}></HelpCircle>
                                    </OverlayTrigger>
                                </Form.Label>
                                <Form.Select
                                    id="model"
                                    name="model"
                                    value={values.model}
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                >
                                    {
                                        BOT_GPT_MODEL.getKeys().map((item, i) => {
                                            return <option key={i} value={item}>{t(item)}</option>
                                        })
                                    }
                                </Form.Select>
                            </Form.Group>

                            <Form.Group className="mb-3">
                                <Form.Label htmlFor="count_generations" className="d-flex align-items-center" >
                                    {t("GPT Number generations")}
                                    <OverlayTrigger placement='right'
                                        overlay={
                                            <Popover className="clearPopover" id={`popover-positioned-bottom`}>
                                                <Popover.Body style={{ cursor: 'default' }}>{t("GPT Number generations Hint")}</Popover.Body>
                                            </Popover>
                                        }
                                    >
                                        <HelpCircle className="icon-hover" style={{ cursor: 'help', marginLeft: '.5em', opacity: '.85' }} size={18}></HelpCircle>
                                    </OverlayTrigger>
                                </Form.Label>
                                <div style={{ display: 'flex' }}>
                                    <Form.Range
                                        id="count_generations"
                                        //type="number"
                                        name="count_generations"
                                        value={values.count_generations}
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        min={1}
                                        max={10}
                                        step={1}
                                    />
                                    <Form.Label htmlFor="count_generations" style={{ minWidth: '40px', textAlign: 'center' }}>{values.count_generations}</Form.Label>
                                </div>
                                {!!touched.count_generations && (
                                    <Form.Control.Feedback type="invalid">
                                        {errors.count_generations}
                                    </Form.Control.Feedback>
                                )}
                            </Form.Group>

                            <Form.Group className="mb-3">
                                <Form.Label htmlFor="temperature" className="d-flex align-items-center" >
                                    {t("GPT Temperature")}
                                    <OverlayTrigger placement='right'
                                        overlay={
                                            <Popover className="clearPopover" id={`popover-positioned-bottom`}>
                                                <Popover.Body style={{ cursor: 'default' }}>{t("GPT Temperature Hint")}</Popover.Body>
                                            </Popover>
                                        }
                                    >
                                        <HelpCircle className="icon-hover" style={{ cursor: 'help', marginLeft: '.5em', opacity: '.85' }} size={18}></HelpCircle>
                                    </OverlayTrigger>
                                </Form.Label>
                                <div style={{ display: 'flex' }}>
                                    <Form.Range
                                        id="temperature"
                                        //type="number"
                                        name="temperature"
                                        value={values.temperature}
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        min={.01}
                                        max={1.0}
                                        step={0.01}
                                    />
                                    <Form.Label htmlFor="temperature" style={{ minWidth: '40px', textAlign: 'center' }}>{values.temperature}</Form.Label>
                                </div>
                                {!!touched.temperature && (
                                    <Form.Control.Feedback type="invalid">
                                        {errors.temperature}
                                    </Form.Control.Feedback>
                                )}
                            </Form.Group>

                            <Form.Group className="mb-3">
                                <Form.Label htmlFor="top_p" className="d-flex align-items-center" >
                                    {t("GPT Top p")}
                                    <OverlayTrigger placement='right'
                                        overlay={
                                            <Popover className="clearPopover" id={`popover-positioned-bottom`}>
                                                <Popover.Body style={{ cursor: 'default' }}>{t("GPT Top p Hint")}</Popover.Body>
                                            </Popover>
                                        }
                                    >
                                        <HelpCircle className="icon-hover" style={{ cursor: 'help', marginLeft: '.5em', opacity: '.85' }} size={18}></HelpCircle>
                                    </OverlayTrigger>
                                </Form.Label>
                                <div style={{ display: 'flex' }}>
                                    <Form.Range
                                        id="top_p"
                                        //type="number"
                                        name="top_p"
                                        value={values.top_p}
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        min={.10}
                                        max={1.0}
                                        step={0.01}
                                    />
                                    <Form.Label htmlFor="top_p" style={{ minWidth: '40px', textAlign: 'center' }}>{values.top_p}</Form.Label>
                                </div>
                                {!!touched.top_p && (
                                    <Form.Control.Feedback type="invalid">
                                        {errors.top_p}
                                    </Form.Control.Feedback>
                                )}
                            </Form.Group>

                            <Form.Group className="mb-3">
                                <Form.Label htmlFor="max_tokens" className="d-flex align-items-center" >
                                    {t("GPT Max tokens") + ' (' + BOT_GPT_MODEL.getMaxTokens(values.model) + ')'}
                                    <OverlayTrigger placement='right'
                                        overlay={
                                            <Popover className="clearPopover" id={`popover-positioned-bottom`}>
                                                <Popover.Body style={{ cursor: 'default' }}>{t("GPT Max tokens Hint")}</Popover.Body>
                                            </Popover>
                                        }
                                    >
                                        <HelpCircle className="icon-hover" style={{ cursor: 'help', marginLeft: '.5em', opacity: '.85' }} size={18}></HelpCircle>
                                    </OverlayTrigger>
                                </Form.Label>
                                <div style={{ display: 'flex' }}>
                                    <Form.Range
                                        id="max_tokens"
                                        //type="number"
                                        name="max_tokens"
                                        value={values.max_tokens}
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        min={1}
                                        max={BOT_GPT_MODEL.getMaxTokens(values.model)}
                                        step={1}
                                    />
                                    <Form.Label htmlFor="max_tokens" style={{ minWidth: '40px', textAlign: 'center' }}>{values.max_tokens}</Form.Label>
                                </div>
                                {!!touched.max_tokens && (
                                    <Form.Control.Feedback type="invalid">
                                        {errors.max_tokens}
                                    </Form.Control.Feedback>
                                )}
                            </Form.Group>

                            <Form.Group className="mb-3">
                                <Form.Label htmlFor="presence_penalty" className="d-flex align-items-center" >
                                    {t("GPT Presence penalty")}
                                    <OverlayTrigger placement='right'
                                        overlay={
                                            <Popover className="clearPopover" id={`popover-positioned-bottom`}>
                                                <Popover.Body style={{ cursor: 'default' }}>{t("GPT Presence penalty Hint")}</Popover.Body>
                                            </Popover>
                                        }
                                    >
                                        <HelpCircle className="icon-hover" style={{ cursor: 'help', marginLeft: '.5em', opacity: '.85' }} size={18}></HelpCircle>
                                    </OverlayTrigger>
                                </Form.Label>
                                <div style={{ display: 'flex' }}>
                                    <Form.Range
                                        id="presence_penalty"
                                        //type="number"
                                        name="presence_penalty"
                                        value={values.presence_penalty}
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        min={-2.0}
                                        max={2.0}
                                        step={0.01}
                                    />
                                    <Form.Label htmlFor="presence_penalty" style={{ minWidth: '40px', textAlign: 'center' }}>{values.presence_penalty}</Form.Label>
                                </div>
                                {!!touched.presence_penalty && (
                                    <Form.Control.Feedback type="invalid">
                                        {errors.presence_penalty}
                                    </Form.Control.Feedback>
                                )}
                            </Form.Group>

                            <Form.Group className="mb-3">
                                <Form.Label htmlFor="frequency_penalty" className="d-flex align-items-center" >
                                    {t("GPT Frequency penalty")}
                                    <OverlayTrigger placement='right'
                                        overlay={
                                            <Popover className="clearPopover" id={`popover-positioned-bottom`}>
                                                <Popover.Body style={{ cursor: 'default' }}>{t("GPT Frequency penalty Hint")}</Popover.Body>
                                            </Popover>
                                        }
                                    >
                                        <HelpCircle className="icon-hover" style={{ cursor: 'help', marginLeft: '.5em', opacity: '.85' }} size={18}></HelpCircle>
                                    </OverlayTrigger>
                                </Form.Label>
                                <div style={{ display: 'flex' }}>
                                    <Form.Range
                                        id="frequency_penalty"
                                        //type="number"
                                        name="frequency_penalty"
                                        value={values.frequency_penalty}
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        min={-2.0}
                                        max={2.0}
                                        step={0.01}
                                    />
                                    <Form.Label htmlFor="frequency_penalty" style={{ minWidth: '40px', textAlign: 'center' }}>{values.frequency_penalty}</Form.Label>
                                </div>
                                {!!touched.frequency_penalty && (
                                    <Form.Control.Feedback type="invalid">
                                        {errors.frequency_penalty}
                                    </Form.Control.Feedback>
                                )}
                            </Form.Group>

                        </Card.Body>
                    </Card>

                    <div className="text-center mt-3" style={{ maxWidth: "800px" }}>
                        <Button type="submit" variant="primary" disabled={isSubmitting}>
                            {t("Save changes")}
                        </Button>
                    </div>
                </Form>
            )
            }
        </Formik >
    );
};

export default GPTSettings;
