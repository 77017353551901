import React, { useEffect, useState } from "react";
import { Formik } from "formik";
import {
    Button,
    Form,
    Spinner
} from "react-bootstrap";

import { X, Check } from "react-feather";

import { SOURCE_TYPES } from "../../../contexts/sources/SourcesContext";
import useBots from "../../../hooks/useBots";
import useSources from "../../../hooks/useSources";
import useAnalytics from "../../../hooks/useAnalytics";
import * as Yup from "yup";
import useMessage from "../../../hooks/useMessage";
import { useTranslation } from "../../../hooks/useLocalization";


import useQubot from "../../../hooks/useQubot";
import GPTSourcesTabs from "./GPTSourcesTabs";

const NewGPTSource = () => {
    const { t, } = useTranslation();
    const { showMessage, showMessageWithDebug } = useMessage();
    const { activeBot, qubotType } = useBots();
    const { createSource, createSources } = useSources();
    const { id, lang } = activeBot || {};
    const { CONSOLE_EVENTS } = useAnalytics(); // {request:"addEvents",events:this.events}

    const qubot = useQubot();
    const type = qubotType(activeBot);

    const [isSubmitingProcess, setSubmittingProcess] = useState(false)
    const [isSubmitingResult, setSubmittingResult] = useState(null)

    useEffect(() => {
        qubot?.showChat({ botID: id, type }, { start: false, lang: lang });
    }, [qubot, id, lang, type]);

    useEffect(() => {
        return () => !qubot?.isPageHasChat() && qubot?.hideChat();
    }, [qubot]);

    const toBase64 = (file) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => {
                let encoded = reader.result.toString().replace(/^data:(.*,)?/, "");
                if (encoded.length % 4 > 0) {
                    encoded += "=".repeat(4 - (encoded.length % 4));
                }
                resolve(encoded);
            };
            reader.onerror = (error) => reject(error);
        });
    }

    const getMimeType = (mimetype, filename) => {
        if (mimetype)
            return mimetype
        else {
            let ext = filename.substring(filename.lastIndexOf('.'))
            switch (ext) {
                case '.yml': case '.yaml':
                    return 'application/yaml'
                default:
                    return ''
            }
        }
    }

    const genSpinner = () => {
        if (isSubmitingProcess)
            return <>
                <Spinner animation="border" key="Warning" variant="warning" size="sm" />
            </>
        else if (isSubmitingResult === false)
            return <>
                <X className="text-danger" />
            </>
        else if (isSubmitingResult)
            return <>
                <Check className="text-success" />
            </>
    }

    return (
        <Formik
            enableReinitialize
            initialValues={{
                title: '',
                type: 0,
                data: { text: '', link: '', file: '' }
            }}
            validationSchema={Yup.object().shape({
                title: Yup.string().max(64),
            })}
            onSubmit={async (values, { setSubmitting, resetForm }) => {
                setSubmittingProcess(true)
                let isSubmitted = true
                let created_source = null
                let data = {}
                let len = values.title.length
                let logs = null
                try {
                    setSubmitting(true);
                    setSubmittingResult(true)
                    values.type = +document.getElementById('source_type').value
                    switch (values.type) {
                        case SOURCE_TYPES.LINK.value:
                            if ((values.data.link.length > 0) && (values.data.link.indexOf('https://') === -1)) {
                                values.data.link = 'https://' + values.data.link
                            }
                            len += values.data.link.length
                            data = { 'link': values.data.link, 'text': '...' }
                            created_source = await createSource({ ...values, 'data': data, 'length': len })
                            break;
                        case SOURCE_TYPES.FILE.value:
                            let files = document.getElementById('source_data_file').files;
                            if (files.length === 0) throw new Error(t('Please, choose the file'));
                            data = {
                                'name': files[0].name,
                                'mimetype': getMimeType(files[0].type, files[0].name),
                                'data': await toBase64(files[0]),
                                'text': '...'
                            }
                            created_source = await createSource({ ...values, 'data': data, 'length': len })
                            break;
                        case SOURCE_TYPES.SITEMAP.value:
                            let links = document.querySelectorAll('#sitemaps_links a')
                            let sources = []
                            for (let i = 0; i < links.length; i++) {
                                let link = links[i].getAttribute('href')
                                data = { 'link': link, 'text': '...' }
                                sources.push({ ...values, 'type': SOURCE_TYPES.LINK.value, 'data': data, 'length': link.length })
                            }
                            if (sources.length === 0) throw new Error(t('Please click Get Links and select links.'));
                            created_source = await createSources(sources)
                            break;
                        default:
                            len += values.data.text.length
                            data = { 'text': values.data.text }
                            created_source = await createSource({ ...values, 'data': data, 'length': len })
                    }

                    if (created_source?.debug?.logs?.length > 0) {
                        if (created_source?.debug?.logs?.length === 1) {
                            throw new Error(t(created_source.debug.logs[0].error))
                        } else {
                            logs = { logs: [] }
                            for (let i = 0; i < created_source.debug.logs.length; i++) {
                                let log = created_source.debug.logs[i]
                                logs.logs.push({
                                    level: 'error',
                                    text: log.error
                                })
                            }
                            throw new Error(t("Sources create error"))
                        }
                    }
                    await CONSOLE_EVENTS.EV_CreateSource.send({ params: { ...values } })
                } catch (error) {
                    const { message = t("Something went wrong"), debug } = error;
                    showMessageWithDebug({ error: message, debug: debug || logs });
                    isSubmitted = false
                    setSubmittingResult(false)
                } finally {
                    if (isSubmitted) {
                        showMessage({ save: "" })
                        resetForm()
                    }
                    setSubmitting(false);
                    setSubmittingProcess(false)
                }
            }}
        >
            {({
                errors,
                handleBlur,
                handleChange,
                handleReset,
                handleSubmit,
                isSubmitting,
                touched,
                values,
            }) => (
                <Form onSubmit={(e) => { handleSubmit(e); }}>
                    <GPTSourcesTabs values={values} touched={touched}
                        errors={errors} handleBlur={handleBlur} handleChange={handleChange} />
                    <div className="text-center mt-3" style={{ maxWidth: "800px" }}>
                        <div>
                            <Button type="submit" variant="primary" disabled={isSubmitting} className="me-2">
                                {t("Save source")}
                            </Button>
                            {genSpinner()}
                        </div>
                    </div>
                </Form>
            )
            }
        </Formik >
    );
};

export default NewGPTSource;
