import React, { useCallback, useEffect, useState } from "react";
import { DollarSign, Globe, HelpCircle, LogOut } from "react-feather";
import avatar from "../../assets/img/avatars/no-avatar.jpg";
import { config } from "../../config";
import SidebarNav from "./SidebarNav";
import { useNavigate } from "react-router-dom";
import useAuth from "../../hooks/useAuth";
import { useTranslation } from "../../hooks/useLocalization";
import { isMobile } from "../../utils/screen";
import useSidebar from "../../hooks/useSidebar";

const SidebarFooter = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { user, signOut } = useAuth();
  const { isOpen, setIsOpen } = useSidebar();
  const [items, setItems] = useState([]);
  const { displayName } = user || {};

  const navItems = useCallback(() => {
    let pages = [
      {
        kind: "user",
        href: "/account/settings",
        icon: () => (
          <img
            src={avatar}
            className="avatar sm img-fluid rounded-circle me-2"
            alt="Avatar"
          />
        ),
        title: displayName,
      },
    ];

    if (user) {
      const subPages = [];
      subPages.push.apply(subPages, [
        {
          kind: "language",
          icon: Globe,
          title: t("Language"),
        },
        {
          // href: "/account/payments",
          icon: DollarSign,
          title: t("Payments"),
          children: [
            {
              href: "/account/pricing",
              title: t("Subscription"),
            }
          ]
            .concat([
              {
                href: "/account/payments",
                title: t("Payments history"),
              },
            ])
            .concat([
              {
                href: "/account/traffic",
                title: t("Traffic tracking"),
              },
            ]),
        },
        {
          kind: "link",
          href: config.links?.help,
          icon: HelpCircle,
          title: t("Help"),
        },
        {
          kind: "action",
          icon: LogOut,
          title: t("Logout"),
          onClick: async () => {
            await signOut();
            if (isMobile() && !isOpen) {
              setIsOpen(true);
            }
            navigate("/auth");
          },
        },
      ]);
      pages.push.apply(pages, subPages);
    }

    return [{ title: "", pages }];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, displayName, t, isOpen]);

  useEffect(() => setItems(navItems()), [navItems]);

  return (
    <div className="sidebar-cta">
      <SidebarNav items={items} />
    </div>
  );
};

export default SidebarFooter;
