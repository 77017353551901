import React, { forwardRef, useCallback, useEffect, useState } from "react";
import { Alert, Button, Col, Row } from "react-bootstrap";
import { Check, Clock, Terminal, X } from "react-feather";
import { useNavigate } from "react-router";
import BotCard from "./cards/BotCard";
import BotNewCard from "./cards/BotNewCard";
import BotNewGPTCard from "./cards/BotNewGPTCard";
import { BOT_STATUS } from "../../contexts/bots/BotsContext";
import useBots from "../../hooks/useBots";
import useMessage from "../../hooks/useMessage";
import CreateBot from "../../modals/bots/CreateBot";
import CreateGPTBot from "../../modals/bots/CreateGPTBot";
import CloneBot from "../../modals/bots/CloneBot";
import { scrollTo, scrollIntoView } from "../../utils/scroll";
import { BOT_FILTER, SORT_BY } from "../../pages/dashboards/Bots/utils";
import useQubot from "../../hooks/useQubot";
import Wordpress from "../../modals/Wordpress";
import { useTranslation } from "../../hooks/useLocalization";

import { BOTS_TYPE } from "../../pages/dashboards/Bots/utils";

const MyBots = forwardRef(({
  showTitle,
  filter,
  filterBot,
  sortBy,
  search,
  setSearch,
  searchResults,
  handlers,
  botType,
}, ref) => {
  const { t, tt } = useTranslation();
  const {
    bots,
    settings,
    activeBot,
    setBotActive,
    downloadBot,
    accessBotLevels,
    qubotType,
  } = useBots();
  const qubot = useQubot();
  const navigate = useNavigate();
  // const location = useLocation();
  // const { pathname } = location;
  const { showMessageWithDebug } = useMessage();
  const [download, setDownload] = useState();
  const [autoScrolled, setAutoScrolled] = useState();
  const [showClone, setShowClone] = useState();
  const [showCloneBot, setShowCloneBot] = useState();
  const [showPlay, setShowPlay] = useState();
  const [showPlayBot, setShowPlayBot] = useState();
  const [showCreate, setShowCreate] = useState();
  const [showGPTCreate, setShowGPTCreate] = useState();
  const [showWordpress, setShowWordpress] = useState();

  useEffect(() => {
    if (bots && activeBot && !autoScrolled) {
      scrollTo(activeBot.id);
      scrollIntoView(activeBot.id);
      setAutoScrolled(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bots, activeBot]);

  useEffect(() => {
    if (showPlay && showPlayBot) {
      const { id: botID } = showPlayBot;
      const type = qubotType(showPlayBot);
      qubot?.showChat({ botID, type }, { start: true });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [qubot, showPlay, showPlayBot]);

  useEffect(() => {
    return () => !qubot?.isPageHasChat() && qubot?.hideChat();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const cancelSearch = () => {
    setSearch("");
  };

  const onBotNavigate = (id, path) => () => {
    setBotActive(id);
    navigate(path);
  };

  const getHandlers = (id, levels, gpt = false) => {
    let list = {};

    if (!id) {
      if (gpt) {
        list = {
          ...list,
          onCreate: () => setShowGPTCreate(true),
        };
      } else {
        list = {
          ...list,
          onCreate: () => setShowCreate(true),
          onWordpress: () => setShowWordpress(true),
        };
      }
    }

    if (levels.clone) {
      list = {
        ...list,
        onClone: (bot) => {
          setShowCloneBot(bot);
          setShowClone(true);
        },
      };
    }

    if (levels.play) {
      list = {
        ...list,
        onPlay: (bot) => {
          setShowPlayBot(bot);
          setShowPlay(true);
        },
      };
    }

    if (levels.manage) {
      list = {
        ...list,
        onManage: onBotNavigate(id, "/bots/settings"),
      };
    }

    if (levels.files) {
      list = {
        ...list,
        onFiles: onBotNavigate(id, "/bots/files"),
      };
    }

    if (levels.testing) {
      list = {
        ...list,
        onDownload: async () => {
          try {
            setDownload(id);
            await downloadBot(id);
          } catch (error) {
            const { debug } = error;
            showMessageWithDebug({ error: t("Failed to download bot"), debug });
          } finally {
            setDownload(null);
          }
        },
      };
    }

    if (levels.gptbot && levels.gptsettings) {
      list = {
        ...list,
        onSelect: onBotNavigate(id, "/bots/gptsettings"),
      };
    } else if (levels.editor) {
      list = {
        ...list,
        onSelect: onBotNavigate(id, "/bots/editor"),
      };
    } else if (levels.testing) {
      list = {
        ...list,
        onSelect: onBotNavigate(id, "/bots/testing"),
      };
    } else if (levels.analytics) {
      list = {
        ...list,
        onSelect: onBotNavigate(id, "/bots/analytics"),
      };
    }

    if (levels.channels) {
      list = {
        ...list,
        onChannels: onBotNavigate(id, "/bots/channels"),
      };
    }

    if (levels.analytics) {
      list = {
        ...list,
        onReports: onBotNavigate(id, "/bots/analytics"),
      };
    }

    if (levels.data) {
      list = {
        ...list,
        onData: onBotNavigate(id, "/bots/data"),
      };
    }

    if (handlers) {
      Object.keys(list).forEach((key) => {
        if (typeof handlers[key] === "function") {
          const handler = list[key];
          list[key] = (value) => !handlers[key](value) && handler(value);
        }
      });
    }

    return list;
  };

  const statusStyle = useCallback(
    ({ status }) => {
      switch (status) {
        case BOT_STATUS.REVIEW:
          return {
            statusText: t("Under review"),
            statusVariant: "warning",
            statusIcon: Clock,
          };
        case BOT_STATUS.PUBLISH:
          return {
            statusText: t("Published"),
            statusVariant: "success",
            statusIcon: Check,
          };
        case BOT_STATUS.MAINTENANCE:
          return {
            statusText: t("Maintenance"),
            statusVariant: "danger",
            statusIcon: X,
          };
        default:
          return {
            statusText: t("In development"),
            statusVariant: "secondary",
            statusIcon: Terminal,
          };
      }
    },
    [t]
  );

  const matchSearch = (search, value) => {
    if (typeof value === "string") {
      value = value.toLowerCase();
      search = search.trim().toLowerCase();
      if (value === search) {
        return [0, search.length];
      }
      const index = value.indexOf(search);
      return index > -1 ? [index, index + search.length] : null;
    }
    return null;
  };

  const { recentIDs = [] } = settings || {};
  const showBots = (
    filterBot ? bots.filter((bot) => filterBot(bot, filter)) : bots
  )
    .filter(
      search
        ? ({ id, bundle, name, author }) =>
          [id, bundle, name, author?.displayName].some((value) =>
            matchSearch(search, value)
          )
        : (v) => v
    )
    .filter(
      botType === BOTS_TYPE.GPT
        ? ({ bot_type }) => (bot_type === 'gpt')
        : botType === BOTS_TYPE.EDITOR
          ? ({ id, bot_type }) => (!bot_type && id)
          : (v) => v
    )
    .sort(
      sortBy === SORT_BY.NAME
        ? ({ name: a = "" }, { name: b = "" }) => {
          if (a && b) {
            a = a.toLowerCase();
            b = b.toLowerCase();
            return a < b ? -1 : a > b ? 1 : 0;
          }
          return a ? 1 : -1;
        }
        : ({ id: a }, { id: b }) => {
          if (a && b) {
            const i = recentIDs.indexOf(a);
            const j = recentIDs.indexOf(b);
            return i > -1 && j > -1 ? i - j : i > -1 ? -1 : 1;
          }
          return a ? 1 : -1;
        }
    );

  const renderSearchFallback = search && searchResults && !showBots?.length;
  const renderTitle = showTitle && showBots?.length > 0;

  return (
    <div>
      {renderTitle &&
        <h3
          ref={ref}
          className="mb-3 d-inline-block"
        >
          {t(BOT_FILTER.title(filter))}
        </h3>
      }
      {renderSearchFallback && (
        <Alert
          variant="primary"
          className="alert-outline mt-5 mb-4"
          dismissible
          onClose={cancelSearch}
        >
          <div className="alert-message">
            <h4 className="alert-heading">{t("Woops")}</h4>
            {tt("Bots not found", <b>{search}</b>)}
            <hr />
            <Button variant="secondary" className="me-1" onClick={cancelSearch}>
              {t("Clear")}
            </Button>
          </div>
        </Alert>
      )}
      <Row>
        {showBots.map((bot, index) => {
          const { id } = bot;
          const key = id || index;
          const className = "d-flex flex-grow-auto";
          const { statusText, statusVariant, statusIcon } = statusStyle(bot);
          if (id) {
            return (
              <Col id={id} className={className} key={key}>
                <BotCard
                  {...bot}
                  isDownload={download === id}
                  selected={activeBot?.id === id}
                  statusText={statusText}
                  statusVariant={statusVariant}
                  statusIcon={statusIcon}
                  search={search}
                  matchSearch={matchSearch}
                  showInfo={
                    filter === BOT_FILTER.MY || filter === BOT_FILTER.SHARED
                  }
                  handlers={getHandlers(id, accessBotLevels(bot))}
                  isAboutUs={index === 1 && filter === BOT_FILTER.TEMPLATE}
                />
              </Col>
            );
          }
          if (index === 0)
            return (
              <Col className={className} key={key}>
                <BotNewGPTCard handlers={getHandlers(id, accessBotLevels(bot), true)} />
              </Col>
            );
          else if (index === 1) {
            return (
              <Col className={className} key={key}>
                <BotNewCard handlers={getHandlers(id, accessBotLevels(bot))} />
              </Col>
            );
          }
        })}
        <CloneBot
          bot={showCloneBot}
          open={showClone}
          setOpen={setShowClone}
          onComplete={({ id }) => {
            setBotActive(id);
            navigate("/bots/editor");
          }}
        />
        <CreateBot
          open={showCreate}
          setOpen={setShowCreate}
          onComplete={({ id }) => {
            setBotActive(id);
            navigate("/bots/editor");
          }}
        />
        <CreateGPTBot
          open={showGPTCreate}
          setOpen={setShowGPTCreate}
          onComplete={({ id }) => {
            setBotActive(id);
            navigate("/bots/gptsettings");
          }}
        />
        <Wordpress
          open={showWordpress}
          setOpen={(value) => setShowWordpress(value)}
        />
      </Row>
    </div>
  );
});

export default MyBots;
